import React, { useEffect, useState } from 'react';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import makeStyles from '@mui/styles/makeStyles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { Box, Button, TextField, Typography } from '@mui/material';

import useAPI from 'useAPI';
import { Autocomplete } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Context, UserLocationContext } from 'Store';
import types from 'Reducer/types';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import Loading from 'components/Loading';
import Locations from './Components/Locations';
import { useGeolocation } from './Components/GeolocationContext';
import ScanQRDialog from './Components/ScanQRDialog';
import ShowQRDialog from "./Components/ShowQRDialog"
import CardService from "../services/CardService"
import CarouselService from 'services/CarouselService';
import ParkingSessionService from '../services/ParkingSessionService';
import VehicleService from '../services/VehicleService';
import mixpanel from "mixpanel-browser"
import UserService from "../services/UserService"
import GenerateAlert from './Components/PhoneAlert';
import LocationService from "../services/LocationService"
import HelpButton from './Components/HelpButton';

const useStyles = makeStyles((theme) => ({
  ...styles,
  float: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  floatPin: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: 30,
    left: 20,
    position: 'fixed',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  parkButton: {
    marginTop: 20,
    color: 'white',
    fontSize:20,
    lineHeight: '1.2em',
    backgroundColor: '#009900',
    '&:hover': {
      backgroundColor: '#008000',
    },
    '&:active': {
      backgroundColor: '#008000',
    },
  },
  secondaryButton: { marginTop: 20, color: 'white', fontSize:20, lineHeight: '1.2em' },
  center: {textAlign: 'center', alignContent: 'center'},
  qrDialog: {textAlign: 'center', alignContent: 'center',
    width: '90%',
    maxWidth: '90%',
    margin: 0,
  },
  dialogTitle: {lineHeight: '1.2em'},
}));

function getDefaultOrFirstApprovedVehicle(vehicles) {
  return vehicles.find((el) => el.is_default && el.is_approved) || vehicles.find((el) => el.is_approved) || vehicles[0];
}

function hasAnyUnapprovedVehicles(vehicles) {
  return !!vehicles.find((el) => !el.is_approved);
}

function haveVehicleApprovalsChanged(vehicles1, vehicles2) {
  if (vehicles1.length !== vehicles2.length) {
    return true;
  }
  for (const oldVeh of vehicles1) {
    const newVeh = vehicles2.find((v) => v.id === oldVeh.id);
    if (!newVeh || oldVeh.is_approved !== newVeh.is_approved) {
      return true;
    }
  }
  return false;
}

function SelectVehicle() {
  const navigate = useNavigate();
  const classes = useStyles();
  const api = useAPI();
  const [isFromPark, setIsFromPark] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [carousels, setCarousels] = useState([]);
  const { userLocation } = React.useContext(UserLocationContext);
  const { state, dispatch } = React.useContext(Context);
  const { user, vehicles = [], parkingSession } = state;
  const [selectedVehicle, setSelectedVehicle] = useState(vehicles.length > 0 ? getDefaultOrFirstApprovedVehicle(vehicles) : '');
  const [assignedCarousel, setAssignedCarousel] = useState(null);
  const [assignmentError, setAssignmentError] = useState(null);
  const [selectedCarousel, setSelectedCarousel] = useState(null);
  const [isParkingEnabled, setParkingEnabled] = useState(false);
  const [isCarouselAvailable, setIsCarouselAvailable] = useState(false);
  const [isScanQR, setScanQR] = useState(false);
  const [parkingToken, setParkingToken] = useState('');
  const [isShowParkingToken, setShowParkingToken] = useState(false);
  const { active: geolocationActive, latitude, longitude, feetFrom } = useGeolocation();
  const [geoPark, setGeoPark] = useState(false);

  useEffect(() => {
    VehicleService.init(api);
    CarouselService.init(api);
    ParkingSessionService.init(api);
    CardService.init(api);
    UserService.init(api);
    if (location.pathname.includes('park')) {
      setIsFromPark(true);
      if (ParkingSessionService.isParked(parkingSession) || !userLocation) {
        navigate('/');
        return;
      }
    }
    CardService.getUserDefaultCard().then((card) => {
      if (!card) {
        UserService.getUser().then((user) => {
          dispatch({ type: types.SET_USER, payload: user });
          if (!user.is_free_parking && location.pathname.includes('park')) {
            navigate('/park/card/new');
          }
        })
      }
    });
    fetchVehicles();
    fetchCarousels();
  }, [api]);

  useEffect(() => {
    maybeSelectCarousel();
  }, [carousels]);

  useEffect(() => {
    setParkingEnabled(selectedVehicle.is_approved);
    maybeSelectCarousel();
  }, [selectedVehicle]);

  useEffect(() => {
    if (assignmentError) {
      setParkingEnabled(false);
    }
  }, [assignmentError]);

  const fetchCarousels = async () => {
    const carousels = await CarouselService.getCarouselsForLocation(userLocation.id);
    setCarousels(carousels);
  };

  const fetchVehicles = async () => {
    const res = await VehicleService.getVehicles();
    dispatch({ type: types.SET_VEHICLES, payload: res });
    if (res.length === 0) {
      const newPath = location.pathname.includes('park') ? '/park/vehicle/new' : '/vehicles/new';
      navigate(newPath);
      return;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!selectedVehicle && vehicles.length > 0) {
      setSelectedVehicle(getDefaultOrFirstApprovedVehicle(vehicles));
    } else if (selectedVehicle && vehicles) {
      const currentVersion = vehicles.find((v) => v.id === selectedVehicle.id);
      if (currentVersion && currentVersion.is_approved !== selectedVehicle.is_approved) {
        setSelectedVehicle(currentVersion);
      }
    }

    let vehicleApprovalTimer = null;
    if (hasAnyUnapprovedVehicles(vehicles)) {
      vehicleApprovalTimer = setInterval(function () {
        checkForVehicleApproval(vehicles);
      }, 5000);
    }
    return () => {
      vehicleApprovalTimer && clearInterval(vehicleApprovalTimer);
    }
  }, [vehicles]);

  const checkForVehicleApproval = async (vehicles) => {
    const res = await VehicleService.getVehicles();
    if (haveVehicleApprovalsChanged(vehicles, res)) {
      dispatch({ type: types.SET_VEHICLES, payload: res });
    }
  };

  const maybeSelectCarousel = () => {
    let assigned = false;
    setAssignmentError(null);
    if (selectedVehicle && carousels.length > 0 && selectedVehicle?.carousel_assignments[0]?.carousel_id) {
      const carousel_assignment = selectedVehicle.carousel_assignments[0].carousel_id;
      const assigned_carousel = carousels.find((carousel) => carousel.id === carousel_assignment);
      if (assigned_carousel.available_spaces > 0) {
        if ('SUV' === selectedVehicle.type && !assigned_carousel.is_suv) {
          setAssignmentError('There is an issue with your assigned carousel. Please contact support.');
        } else {
          setAssignedCarousel(assigned_carousel);
          setSelectedCarousel(null);
          setIsCarouselAvailable(true);
          assigned = true;
        }
      }
    }
    if (!assigned) {
      setAssignedCarousel(null);
      let available = getCarouselsAvailableForParking();
      setIsCarouselAvailable(available.length > 0);
      if (available.length === 1) {
        setSelectedCarousel(available[0]);
      } else {
        setSelectedCarousel(null);
      }
    }
  }

  const onNewVehicle = () => {
    mixpanel.track('ADD_VEHICLE');
    if (isFromPark) {
      navigate('/park/vehicle/new');
    } else {
      navigate('/vehicles/new');
    }
  };

  const onScanQROrGeoPark = () => {
    if (isParkingEnabled) {
      const carouselId = assignedCarousel ? assignedCarousel.id : (selectedCarousel ? selectedCarousel.id : null);
      if (geoPark) {
        mixpanel.track('GEO_PARK');
        ParkingSessionService.geoPark(carouselId, selectedVehicle.id, latitude, longitude).then(() => {
          navigate('/');
        }).catch((err) => {
          alert(err);
        })
      } else {
        if (LocationService.canUseQRReader(userLocation, carouselId)) {
          ParkingSessionService.getParkingToken(selectedVehicle.id, 'PARK', null, carouselId).then((token) => {
            mixpanel.track('SHOW_QR_TO_PARK');
            setParkingToken(token);
            setShowParkingToken(true);
          });
        } else {
          mixpanel.track('OPEN_QR_TO_PARK');
          setScanQR(true);
        }
      }
    }
  };

  const onCancelShowParkingToken = () => {
    setParkingToken('');
    setShowParkingToken(false);
  };

  const checkParkingTokenStatus = () => {
    ParkingSessionService.getParkingTokenStatus(parkingToken)
      .then((status) => {
        if (status) {
          GenerateAlert();
          setTimeout(()=>navigate('/'), 500); // leave time for alert
        }
      })
      .catch((e) => {
        GenerateAlert();
        onCancelShowParkingToken();
        setTimeout(()=>alert(e), 500); // leave time for alert
      });
  };

  useEffect(() => {
    let tokenStatusTimer = null;
    if (isShowParkingToken) {
      tokenStatusTimer = setInterval(function () {
        checkParkingTokenStatus();
      }, 2000);
    }
    return () => {
      tokenStatusTimer && clearInterval(tokenStatusTimer);
    }
  }, [isShowParkingToken]);

  /**
   * Geo-location effects
   */
  useEffect(() => {
    if (!geolocationActive ||
      (userLocation && !userLocation.is_park_with_geolocation) ||
      ('is_allow_geoparking' in user && !user.is_allow_geoparking) ||
      (!assignedCarousel && !selectedCarousel)) {
      setGeoPark(false);
    } else if (userLocation) {
      const feetFromLocation = feetFrom(userLocation);
      if (feetFromLocation > userLocation.parking_radius_ft) {
        setGeoPark(false);
      } else {
        setGeoPark(true);
      }
    }
  }, [userLocation, geolocationActive, latitude, longitude, assignedCarousel, selectedCarousel]);

  const handleQRScanned = (token) => {
    mixpanel.track('SCAN_QR_TO_PARK');
    setScanQR(false);
    ParkingSessionService.getCarouselSlot(token, userLocation.id, selectedVehicle.id).then(() => {
      navigate('/');
    }).catch((err) => {
      alert(err);
    });
  }

  const onEditVehicle = () => {
    if (selectedVehicle) {
      mixpanel.track('EDIT_VEHICLE');
      navigate('/vehicle/' + selectedVehicle.id + '/edit');
    }
  };

  const getVehicleLabel = (vehicle) => {
    if (!vehicle.title) {
      return '';
    }
    return vehicle.is_approved ? vehicle.title : vehicle.title + ' (pending approval)';
  }

  /**
   * For vehicles not assigned to a carousel, find the available ones for the vehicle
   */
  const getCarouselsAvailableForParking = () => {
    const requireSUV = 'SUV' === selectedVehicle.type;
    // 1. filter out carousels without any available and unassigned spaces
    let target = carousels.filter((c) => c.unassigned_available_spaces > 0);
    // 2. filter for SUVs
    if (requireSUV) {
      target = target.filter((c) => c.is_suv);
    }
    return target;
  }

  const getCarouselDescriptor = (carousel) => {
    return (carousel.is_ev && carousel.is_suv) ? '(EV/SUV)' : (carousel.is_ev ? '(EV)' : (carousel.is_suv ? '(SUV)' : ''));
  }

  const getCarouselListItem = (carousel) => {
    return <div key={carousel.id}>
      <GridItem xs={12} sm={12} md={8} align='center' style={{ fontSize: '10pt', color: 'black' }}>
        {carousel.nickname} {getCarouselDescriptor(carousel)}: {carousel.unassigned_available_spaces === 0 ? 'No spaces available' : carousel.unassigned_available_spaces === 1 ? '1 space left' : `${carousel.unassigned_available_spaces} spaces available`}
      </GridItem>
    </div>
  }

  const getCarouselText = () => {
    if (assignmentError) {
      return <Typography style={{ fontSize: '12pt', color: 'red' }}>
        {assignmentError}
      </Typography>
    }
    if (assignedCarousel) {
      return <Typography style={{ fontSize: '12pt', color: 'black' }}>
        Assigned to {assignedCarousel.nickname}
      </Typography>
    }
    if (selectedCarousel) {
      return getCarouselListItem(selectedCarousel);
    }
    let available = getCarouselsAvailableForParking();
    if (available && available.length > 0) {
      return available.map((carousel) => {
        return getCarouselListItem(carousel);
      })
    }

    return <Typography variant='body1' color='primary'>
      No available carousels at selected location
    </Typography>
  }

  const getDriveToCarouselText = () => {
    if (assignmentError) {
      return 'Unable to park in assigned spot';
    }
    if (assignedCarousel) {
      return geoPark ? `Click when directly in front of ${assignedCarousel.nickname}` : `Drive to ${assignedCarousel.nickname} and Scan QR Code`;
    }
    if (selectedCarousel) {
      return geoPark ? `Click when directly in front of ${selectedCarousel.nickname}` : `Drive to ${selectedCarousel.nickname} and Scan QR Code`;
    }
    return 'Drive to Carousel and Scan QR Code';
  }

  const getShowQRText = () => {
    if (assignedCarousel) {
      return `Hold QR code up to reader at ${assignedCarousel.nickname}`;
    }
    if (selectedCarousel) {
      return `Hold QR code up to reader at ${selectedCarousel.nickname}`;
    }
    return 'Hold QR code up to reader at the carousel';
  }

  if (isLoading) {
    return <Loading />;
  }

    return (
    <AuthenticatedContainer>
      <Locations showMenu={false} />
      <GridItem xs={12} sm={12} md={8} align='center' style={{ padding: 10 }}>
        <Typography variant='body1' color='primary'>
          {isFromPark ? 'Select OR Add Vehicle To Park' : 'Manage Vehicle'}
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align='center'></GridItem>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Box p={3}>
          <Autocomplete
            id='combo-box-select-vehicle'
            disableClearable
            options={vehicles}
            getOptionLabel={getVehicleLabel}
            style={{ width: 300, textAlign: 'center' }}
            value={selectedVehicle}
            onChange={(event, newValue) => {
              setSelectedVehicle(newValue);
            }}
            isOptionEqualToValue={(v1, v2) => v1.id === v2.id}
            renderInput={(params) => <TextField {...params} label='Select Vehicle' />}
          />
        </Box>
        {isFromPark && (
          <>
            <Typography variant='subtitle1' color='primary'>
              {userLocation.name}
            </Typography>
            <Box p={1}>
              {getCarouselText()}
            </Box>
          </>)}
        <Box pd={3} px={3}>
          {isFromPark && isCarouselAvailable ? (isScanQR ?
            <ScanQRDialog classes={classes} isOpen={isScanQR} title="Scan QR Code on Carousel" onScan={handleQRScanned} onCancel={()=>setScanQR(false)} /> :
            (isShowParkingToken ? <ShowQRDialog classes={classes} isOpen={isShowParkingToken} title={getShowQRText()} code={parkingToken} onCancel={onCancelShowParkingToken} /> :
            <Button
              onClick={onScanQROrGeoPark}
              color='info'
              variant='contained'
              fullWidth
              className={classes.parkButton}
              disabled={!isParkingEnabled}
            >
              {getDriveToCarouselText()}
            </Button>
          )) : (
            <Button
              onClick={onEditVehicle}
              color='primary'
              variant='contained'
              fullWidth
              className={classes.secondaryButton}
              disabled={!selectedVehicle}
            >
              Edit Selected Vehicle
            </Button>
          )}
        </Box>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align='center' style={{ color: 'black', marginTop: 20 }}>
        OR
      </GridItem>

      <GridItem xs={12} sm={12} md={8} align='center'>
        <Box p={2}>
          <Button
            onClick={onNewVehicle}
            color='primary'
            variant='contained'
            fullWidth
            className={classes.secondaryButton}
          >
            Add New Vehicle
          </Button>
        </Box>
      </GridItem>

      <GridItem xs={12} sm={12} md={8} align='center'>
        <Box p={1}>
          <HelpButton width={'96%'} />
        </Box>
      </GridItem>

    </AuthenticatedContainer>
  );
}

export default SelectVehicle;

