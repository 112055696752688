import React from 'react';

// core components
import GridItem from 'components/Grid/GridItem.js';
import {Box, Button, Divider} from '@mui/material';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import car from '../assets/img/car.svg';
import parkIcon from '../assets/img/svg/park_icon.svg';
import retrieveIcon from '../assets/img/svg/retrieve_icon.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import UserService from '../services/UserService';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {useNavigate} from 'react-router-dom';
import { Context, UserLocationContext } from 'Store';
import types from 'Reducer/types';
import useAPI from 'useAPI';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Paper from '@mui/material/Paper';
import TermsOfUseContent from './Components/TermsOfUseContent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useStripe} from '@stripe/react-stripe-js';
import RefreshIcon from '@mui/icons-material/Refresh';
import Locations from './Components/Locations';
import { useGeolocation } from './Components/GeolocationContext';
import ParkingSessionService from '../services/ParkingSessionService';
import LocationService from '../services/LocationService';
import LinearProgress from '@mui/material/LinearProgress';
import Countdown from 'react-countdown';
import PropTypes from 'prop-types';
import {useConfirm} from "material-ui-confirm";
import mixpanel from 'mixpanel-browser';
import ScanQRDialog from './Components/ScanQRDialog';
import ShowQRDialog from "./Components/ShowQRDialog"
import GenerateAlert from './Components/PhoneAlert';
import HelpButton from './Components/HelpButton';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
    height: 100,
    width: '100%',
    cursor: 'pointer',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  park: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  parkingSubtext: {
    fontSize: 18,
    textAlign: 'left',
    lineHeight: '1.2em',
  },
  geoDebug: {
    fontSize: 14,
    fontFamily: 'monospace',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  cover: {
    width: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  coverParked: {
    width: 120,
    display: 'flex',
    paddingTop: 20,
    justifyContent: 'center',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cardText: {
    font: 'normal normal 900 22px/30px Lato',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: '1',
  },
  smallCardText: {
    font: 'normal normal 900 18px/24px Lato',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: '1',
  },
  smallNote: {
    fontSize: '10pt',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '1.2em',
    paddingTop: 20,
  },
  center: {textAlign: 'center', alignContent: 'center'},
  qrDialog: {textAlign: 'center', alignContent: 'center',
    width: '90%',
    maxWidth: '90%',
    margin: 0,
  },
  qrOverrideButton: {
    marginTop: 20,
    color: 'white',
    fontSize:20,
    lineHeight: '1.2em',
    backgroundColor: '#009900',
    '&:hover': {
      backgroundColor: '#008000',
    },
    '&:active': {
      backgroundColor: '#008000',
    },
  },
  newButton: { marginTop: 20, color: 'white' },
  dialogTitle: {lineHeight: '1.2em'},
}));

const FIVE_MINS_IN_MILLIS = 1000 * 60 * 5;

export default function Home() {
  let navigate = useNavigate();
  const classes = useStyles();
  const stripe = useStripe();
  const api = useAPI();
  const { state, dispatch } = React.useContext(Context);
  const { user, parkingSession = {} } = state;
  const { userLocation } = React.useContext(UserLocationContext);
  const [parkTime, setParkTime] = React.useState('');
  const [failed, setFailed] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [saving, setSaving] = React.useState(false);
  const confirm = useConfirm();
  const {
    active: geolocationActive,
    initializing: geolocationInitializing,
    isDebugMode: isGeolocationDebugMode,
    latitude,
    longitude,
    distanceInFeet,
    feetToDistanceString,
    outlierCount,
    positionCount,
    bufferSize,
  } = useGeolocation();
  const [parkingEnabled, setParkingEnabled] = React.useState(false);
  const [allowQRParkingOverride, setAllowQRParkingOverride] = React.useState(false);
  const [parkingText, setParkingText] = React.useState(null);
  const [parkingSubtext, setParkingSubtext] = React.useState(null);
  const [geoDebug, setGeoDebug] = React.useState(null);

  /** PARK **/
  const parkingChecklist = [
    'Vehicle is in park',
    'Engine is off',
    'Keys and necessary belongings are out of the vehicle',
    'Antennas are stowed',
    'Car lights are off',
    'Doors, trunk, tailgate are closed',
    'There are no people or pets in the vehicle or parking bay',
  ];

  const [scanQRForStowing, setScanQRForStowing] = React.useState(false);
  const [isShowParkingToken, setShowParkingToken] = React.useState(false);
  const [parkingToken, setParkingToken] = React.useState('');
  const [carouselProgress, setCarouselProgress] = React.useState(0);

  /** RETRIEVE **/
  const [readyToLeave, setReadyToLeave] = React.useState(false);
  const [tokenForLeaving, setTokenForLeaving] = React.useState();
  const [paymentSummary, setPaymentSummary] = React.useState([]);
  const [showConfirmPayment, setShowConfirmPayment] = React.useState(false);
  const [showSubmittingPayment, setShowSubmittingPayment] = React.useState(false);
  const [showDriveAway, setShowDriveAway] = React.useState(false);

  /** REPARK **/
  const [readyToRepark, setReadyToRepark] = React.useState(false);
  const [scanQRForReparkRestowing, setScanQRForReparkRestowing] = React.useState(false);
  const [reparkInProgress] = React.useState(false);
  function getReparkChecklist() {
    return [
      `Your vehicle is ready at ${parkingSession?.carousel?.nickname}`,
      'Be cautious when stepping onto the parking platform',
      'Do not move your car at all',
      'Retrieve your items from the vehicle',
      'Close all doors and trunk',
      'Leave your vehicle on the platform and exit the parking bay',
    ];
  }
  const reparkChecklist = getReparkChecklist();

  // Other dialog control
  const [showTAndC, setShowTAndC] = React.useState(false);
  const [showApplyDiscount, setShowApplyDiscount] = React.useState(false);

  // Discount code field
  const [discountCode, setDiscountCode] = React.useState('');
  const [isDiscountCodeFieldError, setIsDiscountCodeFieldError] = React.useState(false);

  // Snackbar alerts
  const [snackbarError, setSnackbarError] = React.useState('');

  const handleEnterDiscountCode = () => {
    setShowApplyDiscount(true);
    mixpanel.track('OPEN_DISCOUNT', {session_id: parkingSession.id});
  }

  const handleCancelDiscount = () => {
    setShowApplyDiscount(false);
    mixpanel.track('CANCEL_DISCOUNT', {session_id: parkingSession.id});
  }

  const handleApplyDiscountCode = async () => {
    if (discountCode === '') {
      setIsDiscountCodeFieldError(true);
      mixpanel.track('APPLY_DISCOUNT_ERROR', {session_id: parkingSession.id});
      return;
    }
    try {
      mixpanel.track('APPLY_DISCOUNT', {session_id: parkingSession.id, discount_code: discountCode});
      const updatedSession = await ParkingSessionService.applyDiscountCode(discountCode, parkingSession.id);
      updateSession(updatedSession);
      setShowApplyDiscount(false);
    } catch (e) {
      console.log('Caught error', e);
      mixpanel.track('APPLY_DISCOUNT_ERROR', {session_id: parkingSession.id, err: e.toString()});
      setSnackbarError(e);
    }
  }

  const handleCloseTAndC = async (agreed) => {
    setSaving(true);
    mixpanel.track(agreed ? 'TNC_AGREE' : 'TNC_DISAGREE')
    const updatedUser = await UserService.updateUser({
      ...user,
      is_new: 0,
      is_agree: agreed ? 1 : 0,
    });
    dispatch({
      type: types.SET_USER,
      payload: updatedUser,
    });
    setShowTAndC(false);
    setSaving(false);
  };
  let activeColor = '#008BE8';
  let disabledColor = '#394253';
  let successColor = '#2E933C';
  let mutedColor = '#CACACA';
  let mainColor = activeColor;

  const updateSession = (session) => {
    if (!!session && (!parkingSession || session.status !== parkingSession.status)) {
      mixpanel.track(`SESSION_${session.status}`, {session_id: session.id});
    } else if (!!parkingSession && !session) {
      mixpanel.track('SESSION_END', {session_id: parkingSession.id});
    }
    dispatch({
      type: types.SET_PARKING_SESSION,
      payload: session,
    });
  }

  const refreshSession = () => {
    ParkingSessionService.getCurrentSession()
      .then((session) => updateSession(session))
      .catch((e) => console.error(e));
  };

  const refreshNotificationCount = () => {
    api.get('notifications-count')
      .then((res) => {
         dispatch({
           type: types.SET_NOTIFICATIONS,
           payload: res.data,
         });
       })
       .catch((e) => console.error(e));
  }

  // Page load effects
  React.useEffect(() => {
    UserService.init(api);
    ParkingSessionService.init(api);
    mixpanel.track('HOME');
    if (user?.phone === '') {
      navigate('/profile', { state: { isRedirectFromHome: true } });
    } else if (user?.is_new) {
      setShowTAndC(true);
    }
    refreshSession();
    refreshNotificationCount();
  }, [api]);

  const updateTime = () => {
    var now = moment();
    var then = moment(parkingSession.start_time);
    var ms = moment(now, 'DD/MM/YYYY HH:mm:ss').diff(moment(then, 'DD/MM/YYYY HH:mm:ss'));
    var d = moment.duration(ms);
    setParkTime(Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss'));
  };

  React.useEffect(() => {
    if (!parkingSession) {
      postRetrieveCleanup();
      return;
    }
    const timersToCleanup = [];
    if (ParkingSessionService.isParked(parkingSession)) {
      setCarouselProgress(0);
      mainColor = disabledColor;
      const updateTimeRef = setInterval(function () {
        updateTime();
      }, 1000);
      timersToCleanup.push(updateTimeRef);
      // While page is open, check on session every 5 mins
      const refreshSessionRef = setInterval(function () {
        refreshSession();
      }, FIVE_MINS_IN_MILLIS);
      timersToCleanup.push(refreshSessionRef);
    } else if (ParkingSessionService.isTransitionalState(parkingSession)) {
      const updateSessionRef = setInterval(function () {
        refreshSession();
      }, 2000);
      timersToCleanup.push(updateSessionRef);
    }
    if (ParkingSessionService.isCarouselInUse(parkingSession)
    ) {
      // Takes ~1 minute
      const carouselTimer = setInterval(() => {
        setCarouselProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 100;
          }
          return oldProgress + (100.0/60.0); // denominator is expected number of secs to stow
        });
      }, 1000);
      timersToCleanup.push(carouselTimer);
    }
    if (ParkingSessionService.isRetrievingReadyToLeave(parkingSession)) {
      setShowDriveAway(true);
    }
    if (ParkingSessionService.isReparkingCarAvailable(parkingSession)) {
      setCarouselProgress(0);
    }
    return () => {
      timersToCleanup.forEach((i) => clearInterval(i));
    }
  }, [parkingSession]);

  const checkParkingTokenStatus = () => {
    ParkingSessionService.getParkingTokenStatus(parkingToken)
      .then((status) => {
        if (status?.confirmed || status?.success || status?.payment?.success) {
          GenerateAlert();
          setTimeout(()=> {
            onCancelShowParkingToken();
            refreshSession();
          }, 500); // leave time for alert
        } else if (status?.totalAmount) { // payment summary
          GenerateAlert();
          setTimeout(() => {
            setTokenForLeaving(parkingToken); // retain parking token to submit with retrieve request
            onCancelShowParkingToken();
            setPaymentSummary(status);
            setShowConfirmPayment(true);
          }, 500); // leave time for alert
        }
      })
      .catch((e) => {
        GenerateAlert();
        setTimeout(() => {
          onCancelShowParkingToken();
          alert(e);
        }, 500);
      });
  };

  React.useEffect(() => {
    let tokenStatusTimer = null;
    if (isShowParkingToken) {
      tokenStatusTimer = setInterval(function () {
        checkParkingTokenStatus();
      }, 2000);
    } else {
      clearInterval(tokenStatusTimer);
    }
    return () => {
      tokenStatusTimer && clearInterval(tokenStatusTimer);
    }
  }, [isShowParkingToken]);

  const handleTokenError = (err) => {
    if (typeof err === 'string') {
      alert(err);
    } else if (err.response?.status === 409) { // Invalid session ID
      alert('Your app is out of date. We will refresh it for you, and then try again to request the QR code.')
    }
    location.reload();
  }

  const onScanQRForStowing = async () => {
    if (ParkingSessionService.isOnPallet(parkingSession)) { // extra check
      if (LocationService.canUseQRReader(userLocation, parkingSession.carousel_id)) {
        // Don't force token scanning at carousel
        try {
          const token = await ParkingSessionService.getParkingToken(parkingSession.vehicle_id, 'STOW', parkingSession.id);
          setParkingToken(token);
          setShowParkingToken(true);
          mixpanel.track('SHOW_QR_TO_STOW', {session_id: parkingSession.id});
        } catch (err) {
          handleTokenError(err);
        }
      } else {
        setScanQRForStowing(true);
        mixpanel.track('OPEN_QR_TO_STOW', {session_id: parkingSession.id});
      }
    }
  }

  const onCancelShowParkingToken = () => {
    setParkingToken('');
    setShowParkingToken(false);
  }

  const handleQRScannedForStowing = (token) => {
    setScanQRForStowing(false);
    mixpanel.track('SCAN_QR_TO_STOW', {session_id: parkingSession.id});
    ParkingSessionService.stowVehicleForParking(token, parkingSession.id).then((session) => {
      updateSession(session);
    }).catch((err) => {
      alert(err);
    });
  }

  const handlePark = async (qrCodeOverride) => {
    if (!user.is_agree) {
      setShowTAndC(true);
      mixpanel.track('SHOW_TC', {is_agree: false});
      return;
    }
    if (parkingEnabled || (qrCodeOverride && allowQRParkingOverride)) {
      navigate('/park/vehicle/select');
    }
  };

  const handleRefresh = async () => {
    mixpanel.track('REFRESH_SESSION', {session_id: parkingSession?.id});
    await refreshSession();
  };

  const handleGetSomethingFromVehicle = async () => {
    if (ParkingSessionService.isParked(parkingSession)) { // extra check
      if (LocationService.canUseQRReader(userLocation, parkingSession.carousel_id)) {
        try {
          // Don't force token scanning at carousel
          const token = await ParkingSessionService.getParkingToken(parkingSession.vehicle_id, 'REPARK', parkingSession.id);
          setParkingToken(token);
          setShowParkingToken(true);
          mixpanel.track('SCAN_QR_TO_REPARK', {session_id: parkingSession.id});
        } catch (err) {
          handleTokenError(err);
        }
      } else {
        mixpanel.track('OPEN_QR_TO_REPARK', {session_id: parkingSession.id});
        setReadyToRepark(true);
      }
    }
  };

  const handleReadyToLeave =async  () => {
    if (ParkingSessionService.isParked(parkingSession)) { // extra check
      if (LocationService.canUseQRReader(userLocation, parkingSession.carousel_id)) {
        try {
          // Don't force token scanning at carousel
          const token = await ParkingSessionService.getParkingToken(parkingSession.vehicle_id, 'RETRIEVE', parkingSession.id);
          setParkingToken(token);
          setShowParkingToken(true);
          mixpanel.track('SCAN_QR_TO_LEAVE', {session_id: parkingSession.id});
        } catch (err) {
          handleTokenError(err);
        }
      } else {
        mixpanel.track('OPEN_QR_TO_LEAVE', {session_id: parkingSession.id});
        setReadyToLeave(true);
      }
    }
  };

  const handleQRScannedForLeaving = (token) => {
    setReadyToLeave(false);
    setTokenForLeaving(token);
    mixpanel.track('SCAN_QR_TO_LEAVE', {session_id: parkingSession.id});
    ParkingSessionService.retrieve(token, parkingSession.id).then((res) => {
      let success = (res?.success || res?.payment?.success);
      if (success) {
        updateSession(res.session);
      } else if (res?.totalAmount) {
        setPaymentSummary(res);
        setShowConfirmPayment(true);
      } else {
        console.error('unknown response', res);
      }
    }).catch((err) => {
      setTokenForLeaving(null);
      alert(err);
    });
  }

  const cancelScanQRForLeaving = () => {
    setReadyToLeave(false);
    mixpanel.track('CANCEL_SCAN_QR_TO_LEAVE', {session_id: parkingSession.id});
  }

  const handleQRScannedForReparkRetrieval = (token) => {
    setReadyToRepark(false);
    mixpanel.track('SCAN_QR_TO_REPARK', {session_id: parkingSession.id});
    ParkingSessionService.retrieveVehicleForRepark(token, parkingSession.vehicle_id).then((session) => {
      updateSession(session);
    }).catch((err) => {
      alert(err);
    });
  }

  const cancelScanQRForReparkRetrieval = () => {
    setReadyToRepark(false);
    mixpanel.track('CANCEL_SCAN_QR_TO_REPARK', {session_id: parkingSession.id});
  }

  /**
   * Safety check is done and ready to show QR scanner to restow
   */
  const onScanQRForRestowing = () => {
    if (LocationService.canUseQRReader(userLocation, parkingSession.carousel_id)) {
      // Don't force token scanning at carousel
      ParkingSessionService.getParkingToken(parkingSession.vehicle_id, 'STOW', parkingSession.id).then((token) => {
        setParkingToken(token);
        setShowParkingToken(true);
        mixpanel.track('SCAN_QR_TO_RESTOW', {session_id: parkingSession.id});
      })
    } else {
      setScanQRForReparkRestowing(true);
      mixpanel.track('OPEN_QR_TO_RESTOW', {session_id: parkingSession.id});
    }
  }

  const handleQRScannedForReparkRestowing = (token) => {
    setScanQRForReparkRestowing(false);
    mixpanel.track('SCAN_QR_TO_RESTOW', {session_id: parkingSession.id});
    ParkingSessionService.stowVehicleForReparking(token, parkingSession.vehicle_id).then((session) => {
      updateSession(session);
    }).catch((err) => {
      alert(err);
    })
  }

  const cancelScanQRForReparkRestowing = () => {
    setScanQRForReparkRestowing(false);
    mixpanel.track('CANCEL_QR_TO_RESTOW', {session_id: parkingSession.id});
  }

  const handleCardAction = (payment_intent_client_secret) => {
    mixpanel.track('HANDLE_CARD_ACTION', {session_id: parkingSession.id});
    stripe
      .handleCardAction(payment_intent_client_secret)
      .then(function (result) {
        if (result.error) {
          setErrorMessage(result.error.message);
          setFailed(true);
          mixpanel.track('HANDLE_CARD_ACTION_FAILED', {session_id: parkingSession.id, err: result.error.message});
        } else {
          setFailed(false);
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server
          setShowSubmittingPayment(true);
          ParkingSessionService.retrieve(tokenForLeaving, parkingSession.id, paymentSummary)
            .then((res) => {
              setShowSubmittingPayment(false);
              const success = (res.success || res.payment.success);
              if (success) {
                updateSession(res.session);
              } else {
                // TODO: test if this can happen
                console.log('Payment failure', res);
              }
            }).catch((err) => {
            console.log('Error confirming payment 2nd time', err);
          });
        }
      }).catch((err) => {
      console.log('Error handling card action', err);
    });

  }

  const handleConfirmPayment = () => {
    setShowConfirmPayment(false);
    setShowSubmittingPayment(true);
    mixpanel.track('CONFIRM_PAYMENT', {session_id: parkingSession.id});
    ParkingSessionService.retrieve(tokenForLeaving, parkingSession.id, paymentSummary)
      .then((res) => {
        setShowSubmittingPayment(false);
        let success = (res?.success || res?.payment?.success);
        if (success) {
          updateSession(res.session);
        } else if (res?.totalAmount) {
          setSnackbarError('Payment amount changed, please re-approve');
          setPaymentSummary(res);
          setShowConfirmPayment(true);
        } else {
          handleCardAction(res.payment.payment_intent_client_secret);
        }
      }).catch((err) => {
        console.log('Error confirming payment', err);
        alert(err);
    })
  };

  const cancelConfirmPayment = () => {
    setShowConfirmPayment(false);
    mixpanel.track('CANCEL_CONFIRM_PAYMENT', {session_id: parkingSession.id});
  };

  /**
   * After retrieval, reload the user and reset some global state
   */
  const postRetrieveCleanup = async () => {
    setCarouselProgress(0);
    setShowDriveAway(false);
  };

  const getRetrieveCardColor = () => {
    if (failed) {
      return 'red';
    }
    if (ParkingSessionService.isParked(parkingSession)) {
      return activeColor;
    }
    return mutedColor;
  };

  const getDiscountButtonBgColor = () => {
    return ParkingSessionService.isDiscountApplied(parkingSession) ? successColor : 'white';
  };

  const getDiscountButtonTextColor = () => {
    return ParkingSessionService.isDiscountApplied(parkingSession) ? 'white' : activeColor;
  };

  const getRetrieveButtonBgColor = () => {
    if (failed) {
      return 'red';
    }
    if (ParkingSessionService.isParked(parkingSession)) {
      return '#FC9F0D';
    }
    return null;
  };

  const ScanQRComp = (isOpen, title, onScan, onCancel) => {
    return <ScanQRDialog classes={classes} isOpen={isOpen} title={title} onScan={onScan} onCancel={onCancel} />
  }

  const ShowParkingToken = (isOpen) => {
    return <ShowQRDialog classes={classes} isOpen={isOpen} title={'Hold QR code up to any reader'} code={parkingToken} onCancel={onCancelShowParkingToken} />
  }

  // Renderer callback with condition
  const SessionCountDownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <span>Canceling parking session</span>;
    } else {
      return <span>Remaining time: &#160;&#160;{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>;
    }
  };

  SessionCountDownRenderer.propTypes = {
    minutes: PropTypes.node.isRequired,
    seconds: PropTypes.node.isRequired,
    completed: PropTypes.node.isRequired
  };

  /**
   * Show a modal dialog box with optional carousel progress bar and/or button
   * @param isOpen whether or not to show the dialog
   * @param title the title of the dialog
   * @param text the text in the dialog
   * @param showCarouselProgress whether or not to show the carousel progress bar
   * @param timeoutSecs Session timeout to show Countdown
   * @param cancelTimeoutFn if timeoutSecs is defined, then this is a function to call for canceling the countdown (if desired)
   * @param cancelTimeoutText if timeoutSecs is defined, then this is the text to show in the cancel button (default 'Cancel')
   */
  const ModalText = (isOpen, title, text, subtext, showCarouselProgress = false, timeoutSecs, cancelTimeoutFn, cancelTimeoutText = 'Cancel') => {
    return <Dialog open={isOpen}
                   aria-labelledby='alert-dialog-title'
                   aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <Typography component='h5' variant='h5'>
          {text}
        </Typography>
        {subtext && <Typography variant='body1'>
          {subtext}
        </Typography>}
        {timeoutSecs &&
          <>
            <Typography component='h6' variant='h6' style={{marginTop: 20}}>
              <Countdown
                date={Date.now() + (Math.max(timeoutSecs, 0) * 1000)}
                renderer={SessionCountDownRenderer}
              />
            </Typography>
            {cancelTimeoutFn &&
              <Button style={{marginTop: 10}}
                onClick={() => {
                  confirm({ description: 'Are you sure you want to cancel?', confirmationText: 'Confirm'})
                    .then(() => cancelTimeoutFn())
                    .catch(() => {
                      // to suppress console logging on cancel
                    });
                }}
              >
                {cancelTimeoutText}
              </Button> }
          </>
        }
        {showCarouselProgress && <LinearProgress variant='determinate' value={carouselProgress} />}
      </DialogContent>
    </Dialog>
  }

  const CompletedStepLabel = styled(StepLabel)({
    '.MuiStepLabel-active': {
      color: 'rgba(0, 0, 0, 0.87) !important',
    },
    '.MuiStepLabel-completed': {
      color: 'rgba(0, 0, 0, 0.87) !important',
    },
  });

  /**
   * Show a safety checklist using a custom MUI stepper that doesn't actually "step".
   * Historically we had the user click a "Next" button after each step, but that was removed.
   * However, the styling of the MUI stepper has remained.
   */
  const SafetyChecklist = (isOpen, checklist, stowFn, stowText, cancelFn, cancelText) => {
    return <Dialog
      open={isOpen}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Safety Check</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' component='div'>
          <Stepper activeStep={0} orientation='vertical'>
            {checklist.map((label) => (
              <Step key={label} completed>
                <CompletedStepLabel>{label}</CompletedStepLabel>
              </Step>
            ))}
          </Stepper>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div style={{textAlign: 'left'}}>
          <Paper square elevation={0} style={{float: 'left', paddingLeft: 20}}>
            <Typography>Thank you for safety check.</Typography>
          </Paper>
          {stowFn &&
            <Button
              onClick={stowFn}
              color='primary'
              variant='contained'
              fullWidth
              className={classes.newButton}
            >
              {stowText}
            </Button> }
          {cancelFn ?
            <Button
              onClick={() => {
                confirm({ description: 'Are you sure you want to cancel?', confirmationText: 'Confirm'})
                  .then(() => cancelFn())
                  .catch(() => {
                    // to suppress console logging on cancel
                  });
              }}
            >
              {cancelText}
            </Button> : <div style={{marginBottom: 10}}/>
          }
        </div>
      </DialogActions>
    </Dialog>
  }

  const cancelSessionFn = async () => {
    const updatedSession = await ParkingSessionService.cancelSession(parkingSession?.id);
    if (updatedSession?.status) { // if an updated session was returned
      updateSession(updatedSession);
    }
  };

  /**
   * Geolocation effects
   */
  React.useEffect(() => {
    if (isGeolocationDebugMode) {
      console.log('userLocation', JSON.stringify(userLocation), 'geolocationActive', geolocationActive, 'isGeolocationDebugMode', isGeolocationDebugMode, 'latitude', latitude, 'longitude', longitude, 'distanceInFeet', distanceInFeet, 'outlierCount', outlierCount, 'positionCount', positionCount, 'bufferSize', bufferSize);
      let text;
      if (geolocationActive) {
        text = `${latitude.toFixed(4)},${longitude.toFixed(4)}`;
        if (distanceInFeet) {
          text += ` ${feetToDistanceString(distanceInFeet)} (Tot|X|Buf ${positionCount}|${outlierCount}|${bufferSize})`;
        }
      } else if (geolocationInitializing) {
        text = 'initializing geolocation'
      } else {
        text = 'geolocation inactive';
      }
      setGeoDebug(text);
    }
    if (!userLocation) {
      setParkingEnabled(false);
      setAllowQRParkingOverride(false);
      setParkingText('PARK');
      setParkingSubtext(null);
    } else if (userLocation?.availableSpaces === 0) {
      setParkingEnabled(false);
      setAllowQRParkingOverride(false);
      setParkingText('LOCATION FULL');
      setParkingSubtext(null);
    } else if (ParkingSessionService.isParked(parkingSession)) {
      setParkingEnabled(false);
      setAllowQRParkingOverride(false);
      setParkingText('PARK');
      setParkingSubtext(null);
    } else if (!userLocation.is_park_with_geolocation ||
      ('is_allow_geoparking' in user && !user.is_allow_geoparking) ||
      geolocationInitializing) {
      setParkingEnabled(true);
      setAllowQRParkingOverride(false);
      setParkingText('PARK');
      setParkingSubtext(null);
    } else if (!geolocationActive) {
      setParkingEnabled(true);
      setAllowQRParkingOverride(false);
      setParkingText('PARK');
      setParkingSubtext('Enable location sharing to avoid exiting your vehicle');
    } else {
      if (distanceInFeet > userLocation.parking_radius_ft) {
        setParkingEnabled(false);
        setAllowQRParkingOverride(true);
        setParkingText(`DRIVE TO LOCATION`);
        setParkingSubtext(`${feetToDistanceString(distanceInFeet)} away`)
      } else {
        setParkingEnabled(true);
        setAllowQRParkingOverride(false);
        setParkingText('PARK');
        setParkingSubtext(null);
      }
    }
  }, [userLocation, geolocationActive, isGeolocationDebugMode, latitude, longitude, distanceInFeet, outlierCount, parkingSession]);

  return (
    <AuthenticatedContainer>
      <>
        <GridItem xs={12} sm={12} md={8} align='center'>

          {ShowParkingToken(isShowParkingToken)}

          {/* Parking */}
          {ModalText(!!ParkingSessionService.isQueuedToPark(parkingSession), 'Queued to Park', 'Please wait for your turn to enter', null, false, null, cancelSessionFn)}
          {ModalText(!!ParkingSessionService.isReadyToEnter(parkingSession), 'Ready to Enter', `Please drive onto ${parkingSession?.carousel?.nickname}`, 'IMPORTANT: Fold side mirrors, and pull forward until tires hit the stop',false,
            parkingSession?.timeout_secs, cancelSessionFn, 'Cancel parking')}
          {SafetyChecklist((!!ParkingSessionService.isOnPallet(parkingSession) && !(scanQRForStowing || isShowParkingToken)), parkingChecklist, onScanQRForStowing, 'Scan QR Code to Stow Vehicle', cancelSessionFn, 'Cancel and Leave')}
          {ScanQRComp(scanQRForStowing, 'Scan QR Code to Stow Vehicle', handleQRScannedForStowing)}
          {ModalText((!!ParkingSessionService.isReadyToStow(parkingSession) || !!ParkingSessionService.isStowing(parkingSession)), 'Stowing Your Car', 'Your car is being stowed...', null,true)}

          {/* Reparking */}
          {ScanQRComp(readyToRepark, 'Scan QR Code to Start Retrieval', handleQRScannedForReparkRetrieval, cancelScanQRForReparkRetrieval)}
          {ModalText(!!ParkingSessionService.isQueuedToRepark(parkingSession), 'In the Queue', `Your vehicle is in the queue to retrieve at ${parkingSession?.carousel?.nickname}`)}
          {ModalText(!!ParkingSessionService.isReparkingWaitingForCar(parkingSession), 'Retrieving Your Car', `Your vehicle is being retrieved at ${parkingSession?.carousel?.nickname}`, null,true)}
          {SafetyChecklist((!!ParkingSessionService.isReparkingCarAvailable(parkingSession) && !(scanQRForReparkRestowing || isShowParkingToken)), reparkChecklist, onScanQRForRestowing, 'Scan QR Code to Re-Stow Vehicle')}
          {ScanQRComp(scanQRForReparkRestowing, 'Scan QR Code to Re-Stow Your Vehicle', handleQRScannedForReparkRestowing, cancelScanQRForReparkRestowing)}

          {/* Retrieving */}
          {ScanQRComp(readyToLeave, 'Scan QR Code to Start Checkout', handleQRScannedForLeaving, cancelScanQRForLeaving)}
          {/* Confirm payment: see below */}
          {ModalText(showSubmittingPayment, 'Submitting Payment', 'Your payment is being submitted...')}
          {ModalText(!!ParkingSessionService.isQueuedToRetrieve(parkingSession), 'In the Queue', `Your vehicle is in the queue to retrieve at ${parkingSession?.carousel?.nickname}`)}
          {ModalText(!!ParkingSessionService.isRetrievingWaitingForCar(parkingSession), 'Retrieving Your Car', `Your vehicle is being retrieved at ${parkingSession?.carousel?.nickname}`, null,true)}
          {ModalText(showDriveAway, 'Thank you for parking with Stak!', 'Please retrieve your car.', null,false)}

          {/* Confirm payment */}
          <Dialog
            open={showConfirmPayment}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth
            maxWidth='sm'
          >
            <DialogTitle id='alert-dialog-title'>{'Confirm Payment'}</DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label='spanning table'>
                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Subtotal</TableCell>
                      <TableCell align='right'>
                        {`$${paymentSummary?.totalAmount?.toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Discount</TableCell>
                      <TableCell align='right'>
                        {`$${paymentSummary?.discountAmount?.toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total</TableCell>
                      <TableCell align='right'>
                        {`$${paymentSummary?.chargedAmount?.toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmPayment} color='primary' autoFocus>
                Confirm Payment
              </Button>
              <Button onClick={cancelConfirmPayment} color='secondary'>
                Cancel, I want to stay
              </Button>
            </DialogActions>
          </Dialog>


          {/* T&C dialog */}
          <Dialog
            open={showTAndC}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Terms and Conditions'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description' component='div'>
                <TermsOfUseContent />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleCloseTAndC(false);
                }}
                color='primary'
              >
                Disagree
              </Button>
              <Button
                onClick={() => {
                  handleCloseTAndC(true);
                }}
                color='primary'
                autoFocus
              >
                Agree
              </Button>
              {saving && <CircularProgress size={14} className={classes.buttonProgress} />}
            </DialogActions>
          </Dialog>

          {/* Apply discount code dialog */}
          <Dialog
            open={showApplyDiscount}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Apply Promo Code'}</DialogTitle>
            <DialogContent>
              <TextField
                error={isDiscountCodeFieldError}
                autoFocus
                margin='dense'
                label='Code'
                value={discountCode}
                onChange={(e) => {
                  setDiscountCode(e.target.value);
                }}
                type='text'
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDiscount} color='primary'>
                Cancel
              </Button>
              <Button onClick={handleApplyDiscountCode} color='primary' autoFocus>
                Apply
              </Button>
            </DialogActions>
          </Dialog>

          <Box>
            <Typography component='h5' variant='h5' style={{ color: 'black', padding: 10 }}>
              What would you like to do?
            </Typography>
          </Box>
        </GridItem>

        {/* car image */}
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Box p={5}>
            <img src={car} alt='Home header' width='100%' />
          </Box>
        </GridItem>
        <Locations />

        {/* PARK box */}
        {ParkingSessionService.isShowParkButton(parkingSession) &&
          <>
            <GridItem xs={12} sm={12} md={8} align='center'>
              <Box>
                <Card
                  className={classes.root}
                  elevation={6}
                  style={{ height: '100%' }}
                  onClick={() => handlePark(false)}
                >
                  <CardMedia
                    className={classes.cover}
                    style={{ backgroundColor: parkingEnabled ? mainColor : mutedColor }}
                    title='Park'
                  >
                    <img src={parkIcon} alt='Park' />
                  </CardMedia>
                  <CardContent className={classes.park}>
                    <h4
                      className={parkingText?.length > 10 ? classes.smallCardText : classes.cardText}
                      style={{ color: parkingEnabled ? mainColor : mutedColor }}
                    >
                      {parkingText}
                    </h4>
                    {parkingSubtext &&
                      <div className={classes.parkingSubtext}>
                        {parkingSubtext}
                      </div>
                    }
                    {isGeolocationDebugMode && geoDebug &&
                      <div className={classes.geoDebug}>
                        {geoDebug}
                      </div>
                    }
                  </CardContent>
                </Card>
              </Box>
            </GridItem>

            {allowQRParkingOverride &&
              <GridItem xs={12} sm={12} md={8} align='center'>
                <Button
                  onClick={() => handlePark(true)}
                  color='info'
                  variant='contained'
                  fullWidth
                  className={classes.qrOverrideButton}
                >
                  Park with QR Code
                </Button>
              </GridItem>
            }
          </>
        }

        {/* RETRIEVE box */}
        {ParkingSessionService.isShowParkingStatus(parkingSession) &&
          <GridItem xs={12} sm={12} md={8} align='center'>
            <Box style={{}}>
              <Card className={classes.root} style={{ height: '100%' }}>
                <CardMedia
                  className={classes.coverParked}
                  style={{
                    backgroundColor: getRetrieveCardColor(),
                  }}
                  title='retrieve'
                >
                  <img
                    src={retrieveIcon}
                    alt='Retrieve'
                    style={{ height: '30px' }}
                  />
                </CardMedia>
                <CardContent
                  className={classes.content}
                  style={
                    {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      width: '100%',
                    }
                  }
                >
                  <GridItem style={{ padding: '0px' }} align='left'>
                    <h4
                      style={{
                        color: activeColor,
                        font: 'normal normal 900 20px/25px Lato',
                      }}
                    >
                      RETRIEVE
                      <RefreshIcon
                        style={{ float: 'right' }}
                        onClick={() => {
                          handleRefresh();
                        }}
                      />
                    </h4>
                  </GridItem>
                  <>
                    <Divider />
                    <Typography variant='body1' align='left' style={{ paddingBottom: 2 }}>
                      <b>Parked Vehicle:</b> {parkingSession.vehicle_name}
                    </Typography>
                    <Typography variant='body1' align='left' style={{ paddingBottom: 2 }}>
                      <b>Parked at:</b>{' '}
                      {moment(parkingSession.start_time).format('MM/DD/YYYY HH:mm:ss')}
                    </Typography>
                    <Typography variant='body1' align='left' style={{ paddingBottom: 5 }}>
                      <b>Park timer:</b> {parkTime}
                    </Typography>

                    { user && !user.is_free_parking &&
                      <>
                        <Button
                          variant='contained'
                          fullWidth={false}
                          style={{
                            color: getDiscountButtonTextColor(),
                            marginTop: 20,
                            lineHeight: '1.2em',
                            backgroundColor: getDiscountButtonBgColor(),
                          }}
                          onClick={handleEnterDiscountCode}
                        >
                          {ParkingSessionService.isDiscountApplied(parkingSession)
                            ? ParkingSessionService.getDiscountDescription(parkingSession)
                            : 'Enter discount code'}
                        </Button>
                      </>
                    }
                    <Button
                      variant='contained'
                      fullWidth
                      disabled={false}
                      size='large'
                      style={{
                        color: 'white',
                        marginTop: 20,
                        lineHeight: '1.2em',
                        backgroundColor: getRetrieveButtonBgColor(),
                      }}
                      onClick={handleReadyToLeave}
                    >
                      READY TO LEAVE
                    </Button>
                    <Button
                      variant='contained'
                      fullWidth
                      disabled={reparkInProgress}
                      size='large'
                      style={{
                        color: 'darkslategray',
                        marginTop: 20,
                        lineHeight: '1.2em',
                        backgroundColor: 'lightyellow',
                      }}
                      onClick={handleGetSomethingFromVehicle}
                    >
                      Get something from vehicle
                    </Button>
                    <Paper square elevation={0}>
                      <Typography className={classes.smallNote}>You must be at the parking lot to scan a QR code in order to retrieve your vehicle for any reason.</Typography>
                    </Paper>
                    <Divider />
                    {failed && (
                      <Typography
                        variant='caption'
                        align='left'
                        style={{
                          paddingBottom: 10,
                          marginTop: 20,
                          color: 'red',
                        }}
                      >
                        {errorMessage}
                      </Typography>
                    )}
                  </>
                </CardContent>
              </Card>
            </Box>
          </GridItem>
        }

        <GridItem xs={12} sm={12} md={8} align='center'>
          <HelpButton marginTop={'15px'}/>
        </GridItem>

        {/* snackbar */}
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Box mt={5}>
            <Snackbar
              open={!!snackbarError}
              autoHideDuration={6000}
              onClose={() => {
                setSnackbarError(null);
              }}
            >
              <Alert
                onClose={() => {
                  setSnackbarError(null);
                }}
                severity='error'
              >
                {snackbarError}
              </Alert>
            </Snackbar>
          </Box>
        </GridItem>
      </>
    </AuthenticatedContainer>
  );
}
